import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

const timeout = 0;

// from https://github.com/ryanwiemer/gatsby-using-page-transitions/blob/master/src/components/Layout.js

const duration = 1;

const variants = {
  initial: {
    opacity: 0
  },
  enter: {
    opacity: 1,
    transition: {
      duration,
      delay: 0,
      when: 'beforeChildren'
    }
  },
  exit: {
    opacity: 0,
    transition: { duration }
  }
};
class PageTransition extends React.PureComponent {
  render() {
    const { props } = this;
    const { children, location } = props;
    return (
      <AnimatePresence>
        <motion.main key={location.pathname} variants={variants} initial="initial" animate="enter" exit="exit">
          {children}
        </motion.main>
      </AnimatePresence>
    );
  }
}

PageTransition.propTypes = {
  children: PropTypes.node, // eslint-disable-line react/require-default-props
  location: PropTypes.objectOf(PropTypes.any).isRequired
};

export default PageTransition;
